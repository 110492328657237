import React from 'react'
import { graphql } from 'gatsby'
import { withStoryGrid } from 'components/storygrid'
import { PeopleCard } from 'components/people/card'
import { PeopleContent } from 'components/people/content'
import { TitleBanner } from 'components/banners/titleBanner'
import BottomNavigation from 'components/BottomNavigation'
import Layout from "../components/layout"
import TeamModeToggle from '../components/PageToggle/TeamToggle'



export default (props) => {
	const image = {
		localFile: props.data.file
	}

	const allMembers = props.data.people.nodes.map(e => ({
		...e,
		position: e.acf.position,
		email: e.acf.email
	}))

	const showModeToggle = props?.data?.wordpressPage?.acf?.show_mode_toggle ?? false
	let layoutProps = {}
	if (showModeToggle) {
		layoutProps = { actionsWidgets: <TeamModeToggle /> }
	}
	const Grid = withStoryGrid(PeopleCard, PeopleContent, allMembers)
	return (
		<Layout
			context={props.pageContext}
			location={props.location}
			ancestors={props.data.wordpressPage.ancestors}
			actionsClassName={'pt-4'}
			{...layoutProps}
		>
			<div style={{ maxWidth: 1240, margin: 'auto' }}>
				<TitleBanner title={props.data.wordpressPage.title} content={props.data.wordpressPage.content} />
				<div className="container mx-auto" style={{ maxWidth: 1240 }}>
					<Grid />
				</div>
				{props.data.wordpressPage.showPageGroupWidget && props.data.wordpressPage.siblingPages && props.data.wordpressPage.siblingPages.length > 0 &&
					<BottomNavigation items={props.data.wordpressPage.siblingPages} />
				}

			</div>
		</Layout>
	)
}

export const query = graphql`
query peopleQuery($id: String!, $people_group: Int!){
    wordpressPage(id:{eq:$id}){
      title
      content
      showPageGroupWidget
      ancestors {
        link
        label
      }
      siblingPages {
        title
        excerpt
        path
      }
acf {
show_mode_toggle
}
    }

    people:allWordpressWpPeople(filter:{people_group:{in: [$people_group]}},sort:{fields:menu_order}) {
      nodes
      {
        title
        content
        people_group
        path

        acf {
          position
          email
        }
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxHeight: 216, maxWidth: 268) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
